import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogProps,
} from 'app/design-lib';
import AudioEditor from 'app/components/AudioEditor/AudioEditor';

interface AudioDialogProps extends Omit<DialogProps, 'children'> {
  mediaId?: string;
  onComplete: (greeting_id: string) => void;
}

export const AudioDialog = ({
  open,
  onClose,
  mediaId,
  onComplete,
}: AudioDialogProps) => {
  return (
    <Dialog open={open} size={'2xl'} onClose={onClose}>
      <AudioContent
        mediaId={mediaId}
        onClose={onClose}
        onComplete={onComplete}
      />
    </Dialog>
  );
};

export const AudioContent = ({ mediaId, onComplete, onClose }) => {
  return (
    <>
      <DialogHeader title={'Play Audio'} onClose={onClose} />
      <DialogContent className={`overflow-y-visible`}>
        <AudioEditor
          hideCancel
          mediaName={`play_audio_${Date.now()}`}
          mediaId={mediaId}
          onComplete={onComplete}
          subType={'play-audio'}
          onClose={onClose}
          hasPlatformDefault={false}
        />
      </DialogContent>
    </>
  );
};
