import { Color, Size, Variant } from 'app/design-lib/types';
import React, { LegacyRef, ReactElement, ReactNode, Ref } from 'react';

export interface IconButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'size' | 'children'
  > {
  component?: any;
  color?: Color;
  size?: Size;
  variant?: Variant;
  href?: string; // if using a link
  to?: string; // if using a link
  children: ReactElement;
  clear?: boolean;
  pill?: boolean;
  ref?: any;
  target?: string;
}

const IconButton = React.forwardRef(
  (
    {
      component = 'button', // or "a" if you want a link
      variant = 'fill',
      size = 'sm',
      color = 'neutral',
      children,
      className: _className,
      clear = false,
      pill = false,
      ...props
    }: IconButtonProps,
    ref:
      | LegacyRef<HTMLButtonElement>
      | Ref<HTMLButtonElement>
      | undefined
      | any,
  ) => {
    const className = `icon-btn ${getSize(
      size,
      clear,
      pill,
    )} ${getVariantStyling(variant, color)} ${_className}`;

    const onClick = props.onClick;
    // let ElementType = button;
    if (typeof component === 'string') {
      return React.createElement(
        component,
        {
          className,
          ...(component === 'button' ? { type: 'button' } : {}),
          ...props,
          onTouchStart: e => {
            e.preventDefault();
            e.stopPropagation();

            onClick && onClick(e);
          },
          ref,
        },
        React.cloneElement(children, {
          className: getIconSize(size, color),
        }),
      );
    }

    let Component = component;
    return (
      <Component
        onTouchStart={e => {
          e.preventDefault();
          e.stopPropagation();
          onClick && onClick(e);
        }}
        className={className}
        ref={ref}
        {...props}
        // @ts-ignore
        ref={undefined}
      >
        {React.cloneElement(children, {
          className: getIconSize(size, color),
        })}
      </Component>
    );
  },
);

const getIconSize = (size: Size, color: Color) => {
  switch (size) {
    case 'sm':
      return `h-4 w-4  `;
    case 'md':
      return `h-4 w-4  `;
    case 'lg':
      return `h-6 w-6  `;
  }
};

const OverlapStyles = (color: Color) => `
  bg-transparent
  hover:bg-transparency-10 
  active:bg-transparency-20
  ${color === 'neutral' ? `active:text-${color}-90` : `active:text-${color}-80`}
  text-${color}-60 
  hover:text-${color}-70 
  disabled:text-neutral-30
`;

const ReverseOverlapStyles = (color: Color) => `
  bg-transparent 
  hover:bg-transparency-30
  active:bg-transparency-60
  text-neutral-20 
  hover:text-neutral-10 
  active:text-white
  disabled:text-neutral-60
`;

const getVariantStyling = (variant: Variant, color: Color) => {
  if (color === 'neutral-reverse') {
    switch (variant) {
      case 'fill':
        return `
      bg-white
      hover:bg-neutral-10 
      active:bg-neutral-20
      disabled:bg-neutral-60
      text-neutral-90
      hover:text-neutral-70
      active:text-neutral-90 
      `;
      case 'outline':
        return `
      border
      border-neutral-60 
      hover:border-neutral-50 
      active:border-neutral-40 
      disabled:border-neutral-60 
      ${ReverseOverlapStyles(color)}    
      `;
      case 'ghost':
        return ReverseOverlapStyles(color);
    }
  }

  switch (variant) {
    case 'fill':
      return `
      bg-${color}-60 
      hover:bg-${color}-70 
      active:bg-${color}-80
      text-white disabled:bg-neutral-20`;
    case 'outline':
      return `
      border
      border-neutral-30 
      hover:border-neutral-40 
      active:border-neutral-50 
      disabled:border-neutral-30 
      ${OverlapStyles(color)}    
      `;
    case 'ghost':
      return OverlapStyles(color);
  }
};

const getSizeStyling = (size: Size, clear?: boolean) => {
  switch (size) {
    case 'sm':
      return `h-6 ${clear ? '' : 'rounded'} text-sm font-medium py-1 px-1.5`;
    case 'md':
      return `h-8 ${clear ? '' : 'rounded-md'} text-md font-medium p-2`;
    case 'lg':
      return `h-10 ${clear ? '' : 'rounded-md'} text-lg font-medium p-3`;
  }
};

const getSize = (size: Size, clear: boolean, pill: boolean) => {
  switch (size) {
    case 'sm':
      return `h-6 w-6 ${clear ? '' : pill ? 'rounded-full' : 'rounded'}`;
    case 'md':
      return `h-8 w-8  min-h-[2rem] ${
        clear ? '' : pill ? 'rounded-full' : 'rounded-md'
      }`;
    case 'lg':
      return `h-8 w-8 ${clear ? '' : pill ? 'rounded-full' : 'rounded-md'}`;
  }
};

export default IconButton;
