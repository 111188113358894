import React, { useRef } from 'react';
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer';
import { TooltipLight } from 'app/design';

import {
  Phone as PhoneIcon,
  Add as AddIcon,
  Audiotrack as AudiotrackIcon,
} from 'app/design/icons-material';

import { useHoverDirty } from 'react-use';

export default function InsertEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) {
  const edgeRef = useRef(null);
  const isHovering = useHoverDirty(edgeRef);
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  console.log('insert note DATA:', data);

  /*
  react-flow supports (on the root ReactFlow)
      onEdgeContextMenu={onEdgeContextMenu}
      onEdgeMouseEnter={onEdgeMouseEnter}
      onEdgeMouseMove={onEdgeMouseMove}
      onEdgeMouseLeave={onEdgeMouseLeave}
      onEdgeDoubleClick={onEdgeDoubleClick}
  */

  return (
    <>
      <path
        id={id}
        ref={edgeRef}
        style={{ ...style, background: isHovering ? undefined : 'yellow' }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* <text>
        <textPath
          href={`#${id}`}
          style={{
            fontSize: '12px',
            transform: 'translate(100,100) rotate(90)',
          }}
          startOffset="50%"
          // textAnchor="middle"
        >
          {data?.text || 'NO LABEL'}
        </textPath>
      </text> */}
    </>
  );
}
