import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  DialogHeader,
} from 'app/design-lib';

import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../../..';

// import { USER_ADDABLE_COMPONENTS } from '../../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../../Strategies/components';

import { StrategySelect } from '../StrategySelect';
// import { buildOutputCallflowFromTemplate } from '../../Template';

import { cloneDeep } from 'lodash';
import { useBuilderContext } from '../../../../IvrBuilder';

export const ChooseDialog = props => {
  const {
    pipe,
    callflow,
    setCallflow,
    modifyPath,
    index,
    requireAllowBefore, // always false
    requireAllowAfter,
    onClose,
    onAdd, // replaces handleAdd from below!
    onAddTemplate,
    templateParent,
    showTemplateList,
  } = props;

  // modifyPath => to root of callflow!

  const ee = useContext(IvrMenuEventEmitterContext);
  const { setSelectedElementKey } = useBuilderContext();
  // useEffectOnce(() => {
  //   ee.on('focus-node', focusNode);
  //   return () => {
  //     ee.removeListener('focus-node', focusNode);
  //   };
  // });

  // console.log('InsertNode:', data);

  const handleAdd = ({ type, options, newDataOpts }) => {
    // TODO: add "recently added" to the data? or use eventemitter?
    // - triggers self to run?
    // - problem if multiple things added??

    // console.log('Add:', type, options, newDataOpts);

    const data = {
      type,
      options,
      data: newDataOpts,
    };

    // const data = {
    //   type: 'MenuGreetingAndTargets',
    // };
    const modules = getAtPath(
      callflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );

    modules.splice(index, 0, data);

    setAtPath(callflow, `${modifyPath}.strategy.data.modules`, modules);

    // console.log('set after insert:', callflow);

    setCallflow({ ...callflow }, { name: `Inserted ${type}` });

    // // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('focus-node', {
    //     key: `${modifyPath}.strategy.data.modules.${index}`,
    //   });
    // }, 100);

    const key = `${modifyPath}.strategy.data.modules.${index}`;
    // TODO: focus after rebuild, instead of on a Timeout
    window.setTimeout(() => {
      setSelectedElementKey(key);
      // console.log('event emitted');
      ee.emit('node-created', {
        key,
        optsPath: `${modifyPath}.strategy.data.modules.${index}`,
      });
    }, 100);

    onClose && onClose();
  };

  const handleAddTemplate = templateCallflow => {
    // TODO: potential problem where we are calling buildOutputCallflowFromTemplate BEFORE we have actually added it to the callflow...
    const newDataOpts = {
      templateId: templateCallflow.id, // "builtin-xyz" or ID of callflow in account (template callflow)
      outputCallflow: cloneDeep(templateCallflow),
      continueTo: [],
      // variables?? part of outputCallflow??
    };
    newDataOpts.outputCallflow.id = 'inline';
    newDataOpts.outputCallflow.changed = true; // to force a rebuild the first time

    // build outputCallflow
    // newDataOpts.outputCallflow = buildOutputCallflowFromTemplate({
    //   rootCallflow: callflow, // root variables
    //   templateCallflow: newDataOpts.outputCallflow, // template-level variables
    // });

    handleAdd({
      type: 'Template',
      options: {},
      newDataOpts,
    });

    // const newData = {
    //   ...templateData,
    //   outputCallflow: cloneDeep(template.doc),
    // };
    // newData.outputCallflow.id = 'inline';
    // newData.outputCallflow.changed = true; // to force a rebuild the first time

    // // build outputCallflow
    // newData.outputCallflow = buildOutputCallflowFromTemplate({
    //   rootCallflow: callflow, // root variables
    //   templateCallflow: newData.outputCallflow, // template-level variables
    // });

    // setAtPath(callflow, `${modifyPath}`, newData);
    // setCallflow(
    //   { ...callflow },
    //   {
    //     name: 'Apply new Template',
    //   }
    // );
  };

  const handleAddGeneric = selected => {
    // console.log('handleAddGeneric', selected);
    handleAdd({
      type: selected.value.type,
      options: selected.value.options,
      newDataOpts: selected.value.data || {},
    });
  };

  const endpointAlreadyInArr = getAtPath(
    callflow,
    `${modifyPath}.strategy.data.modules`,
    [],
  ).find(comp => comp.type === 'TemplateEndpoint');

  return (
    <Dialog open onClose={onClose} size={'4xl'}>
      <DialogHeader title={'Modules'} onClose={onClose} />
      <DialogContent>
        <StrategySelect
          pipe={pipe}
          isInTemplate={templateParent || callflow.type === 'template'}
          endpointAlreadyInArr={endpointAlreadyInArr}
          requireAllowBefore={false}
          requireAllowAfter={requireAllowAfter}
          onChange={onAdd || handleAddGeneric}
          onChooseTemplate={onAddTemplate || handleAddTemplate}
          showTemplateList={showTemplateList}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="negative"
          variant="fill"
          size={'md'}
          className={`self-start`}
          onClick={e => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseDialog;
