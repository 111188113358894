import { get, toInteger } from 'lodash';
import { getAtPath } from '../../utilities';

export const walkMessageStrategyModules = (callflow, walkFn) => {
  walker({
    walkFn,
    callflow,
    modifyPath: '',
    rootData: { callflowIds: [] },
    pipe: null,
  });
};
const walker = ({
  walkFn,
  callflow, // root callflow (use modifyPath to find "current")
  modifyPath,
  rootData, // RootData
  relatedCallflows = {}, // use this to pass in schedules/etc that are needed
  pipe,
}) => {
  let disallowedAddAfter = false;
  let currentCallflow = getAtPath(callflow, modifyPath);

  if (!currentCallflow) {
    return rootData;
  }

  // generic strategy id only!!!
  switch (currentCallflow.message_strategy?.type) {
    case 'blank':
      break;
    default:
      console.error(
        'Invalid currentCallflow message_strategy',
        currentCallflow.message_strategy?.type,
        currentCallflow,
      );
      return rootData;
  }

  // TODO: prevent recursive loops (search previous elements before going to an external callflow ["Extension IVR Menu" or "User Extension"])
  // - determine if callflow is already shown in elements!
  if (currentCallflow.id && currentCallflow.id !== 'inline') {
    if (rootData.callflowIds.includes(currentCallflow.id)) {
      console.info(
        'Already displaying this menu previously in flow! (preventing recursive)',
        currentCallflow,
        rootData.callflowIds,
      );
      return rootData;
    }
    rootData.callflowIds.push(currentCallflow.id);
  }

  // Pass to strategy
  // - strategy returns elements array (MUST already exist!)
  // - TODO: handle ones besides Generic/blank
  let canContinue = true,
    currentIdx = -1;
  for (let infoIdxStr in currentCallflow.message_strategy.data?.modules ?? []) {
    let infoIdx = toInteger(infoIdxStr);
    currentIdx = infoIdx;
    if (!canContinue) {
      continue;
    }
    const moduleItem = currentCallflow.message_strategy.data.modules[infoIdx];

    switch (moduleItem.type) {
      case 'MatchText':
        // link from previous to this one
        // -
        walkFn({
          module: moduleItem,
          callflow,
          currentCallflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
        });

        // expecting this to be the last item in the modules array in the Strategy (menu handles all the options and the timeout/fallback option?)
        // - NOTE: there is NO "children._" for a menu! only "timeout" that works!
        // - TODO: allow ones to come after, but make sure to specify that it is a "fallback" type of Edge relationship/connection
        let targets = moduleItem.data?.targets ?? {};
        Object.keys(targets)
          .sort()
          // eslint-disable-next-line no-loop-func
          .forEach(key => {
            const target = targets[key];

            if (!target?.callflow) {
              // continue/skip
              return;
            }
            rootData = walker({
              walkFn,
              callflow, //: target?.callflow,
              modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.${key}.callflow`,
              rootData,
              relatedCallflows,
              pipe,
            });
          });

        rootData = walker({
          walkFn,
          callflow, //: target?.callflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.no_match.callflow`,
          rootData,
          relatedCallflows,
          pipe,
        });

        break;
      case 'LastCommunicated':
        // match
        rootData = walker({
          walkFn,
          callflow, //: target?.callflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.match.callflow`,
          rootData,
          relatedCallflows,
          pipe,
        });

        // no match
        rootData = walker({
          walkFn,
          callflow, //: target?.callflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.targets.no_match.callflow`,
          rootData,
          relatedCallflows,
          pipe,
        });

        break;

      case 'Schedule':
        walkFn({
          module: moduleItem,
          callflow,
          currentCallflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
        });

        const schedule_id = moduleItem?.data?.schedule_id; // belongs to line (strategy.data.schedules[schedule_id])
        const schedule = callflow?.schedules?.[schedule_id];
        const categories = [...(schedule?.categories ?? [])];

        // add fallback time
        categories.push({
          id: 'fallback',
          name: 'Fallback',
          values: [],
          ...(schedule?.fallback ?? {}),
        });

        categories.forEach((category, i) => {
          // @ts-ignore
          rootData = walker({
            walkFn,
            callflow, //: target?.callflow,
            modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}.data.routes.${category.id}.callflow`,
            rootData,
            relatedCallflows,
            pipe,
          });
        });
        break;

      case 'Message':
      case 'GoToInFlow':
      case 'PlayAudio':
      case 'Note':
      case 'Ring':
      case 'Directory':
      case 'ConferenceRoom':
      case 'Transfer':
      case 'Hangup':
      case 'VoicemailBox':
      case 'Template':
      case 'TemplateEndpoint':
      case 'ContinueToCallflow':
        console.log('mod item', moduleItem);
        walkFn({
          module: moduleItem,
          callflow,
          currentCallflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
        });
        break;
      default:
        console.error('Invalid module type', modifyPath, moduleItem);
        walkFn({
          module: moduleItem,
          callflow,
          currentCallflow,
          modifyPath: `${modifyPath}.message_strategy.data.modules.${infoIdx}`,
        });
        break;
    }
  }

  return rootData;
};
