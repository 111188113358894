import {
  Archery,
  Calendar,
  Cancel,
  Check,
  Clock,
  ClockRotateRight,
  Community,
  DeleteCircle,
  Dialpad,
  Group,
  Message,
  MusicNote,
  Phone,
  PhoneDisabled,
  PhoneIncome,
  Plus,
  QuestionMark,
  SkipNext,
  Strategy,
  TextAlt,
  TimerOff,
  User,
  Voice,
} from 'iconoir-react';
import React from 'react';
import { MenuKeyMap } from '../CallflowSidebar';
import { startCase } from 'lodash';

export const getElementProps = element => {
  if (!element)
    return {
      icon: <QuestionMark fr={undefined} />,
      name: 'Module Missing',
      type: 'Module Missing',
    };

  let icon;
  let name;
  let type;

  let nodeType;
  let color = 'accent';

  switch (element.type) {
    case 'InsertMenuOption':
    case 'InsertScheduleOption':
    case 'InsertMatchTextOption':
      icon = <Plus fr={undefined} />;
      type = 'Add Option';
      name = 'Add Option';
      break;

    case 'OptionNode':
      switch (element.data.targetKey) {
        case '_':
          icon = <Cancel fr={undefined} />;
          type = 'Invalid Entry';
          name = element.data.name ?? type;
          break;
        case 'timeout':
          icon = <TimerOff fr={undefined} />;
          type = 'Menu Timeout';
          name = element.data.name ?? type;
          break;
        default:
          icon = MenuKeyMap.get(element.data.targetKey);
          type = `Dial ${element.data.targetKey}`;
          name = element.data.name ?? type;
          break;
      }
      break;
    case 'OptionStaticNode':
      if (
        element.data.targetKey === 'no_match' ||
        !!element.data.matchTextData
      ) {
        icon = <Cancel fr={undefined} />;
        type = `No Match`;
      } else {
        icon = <Check fr={undefined} />;
        type = 'Match';
      }

      name =
        element.data?.matchTextData?.moduleItem?.data?.no_match?.name ??
        element.data?.menuData?.moduleItem?.data?.targets?.[
          element.data.targetKey
        ]?.name ??
        type;
      break;
    case 'OptionMatchTextNode':
      icon = <Check fr={undefined} />;
      type = `${startCase(
        element.data?.matchTextData.moduleItem.data.targets[
          element.data.targetIdx
        ].match.type,
      )} Match`;
      name =
        element.data?.matchTextData.moduleItem.data.targets[
          element.data.targetIdx
        ].name ?? type;
      break;
    case 'OptionScheduleNode':
      switch (element.data.category.id) {
        case 'fallback':
          icon = <DeleteCircle fr={undefined} />;
          type = 'Schedule Fallback';
          name = element.data.category.name ?? 'Fallback';
          break;
        default:
          icon = <Calendar fr={undefined} />;
          type = 'Schedule Route';
          name = element.data.category.name ?? `Route`;
          break;
      }
      break;
    default:
      const module = element.data?.moduleItem;

      if (!module)
        return {
          icon: <QuestionMark fr={undefined} />,
          name: 'Module Missing',
          type: 'Module Missing',
        };

      switch (module.type) {
        case 'Root':
          nodeType = 'root';
          color = 'positive';
          name = 'Incoming Call';
          type = 'Incoming Call';
          icon = <PhoneIncome fr={undefined} />;
          break;
        // SEND MESSAGE
        case 'Message':
          icon = <Message fr={undefined} />;
          type = `Send Message`;
          name = module.name ?? type;
          nodeType = 'passthrough';
          break;
        case 'GoToInFlow':
          icon = <SkipNext fr={undefined} />;
          type = `Jump to Module`;
          name = module.name ?? type;
          nodeType = 'terminal';
          break;
        // MENU
        // case 'Menu':
        //   icon = <Dialpad fr={undefined} />;
        //   type = `Menu`;
        //   name = module.name ?? type;
        //   nodeType = 'branching';
        //   break;
        // Schedule
        case 'Schedule':
          icon = <Clock fr={undefined} />;
          type = `Schedule`;
          name = module.name ?? type;
          nodeType = 'branching';
          break;
        case 'MatchText':
          icon = <TextAlt fr={undefined} />;
          type = `Text Match`;
          name = module.name ?? type;
          nodeType = 'branching';
          break;
        case 'LastCommunicated':
          icon = <ClockRotateRight fr={undefined} />;
          type = `Communicated Within`;
          name = module.name ?? type;
          nodeType = 'branching';
          break;
        default:
          name = 'Unknown';
          type = `Unknown`;
          icon = <QuestionMark fr={undefined} />;
          console.log('unknown data', element);
          break;
      }
  }

  return { icon, name, type, nodeType, color };
};
