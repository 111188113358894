import React, { useState, useContext, memo, useEffect } from 'react';

import { Archery } from 'iconoir-react';
import { IconButton } from '../../../../../../design-lib';
import { useToggleReducer } from '../../../../../../utilities';
import {
  useBuilderContext,
  useSharedFlow,
} from '../../../../MessageRoutingVisualBuilder';

export const ModeSelectModuleThis = ({ id, modifyPath }) => {
  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const { miniMap, setOpenMiniMap } = useBuilderContext();
  const { selectedElementKey } = useBuilderContext();

  const handleSelectModule = () => {
    sharedFlow.data.onSelect(id);
    setSharedFlow(s => ({ ...s, state: null, data: null }));
    setOpenMiniMap(false);
  };

  const path = sharedFlow.data?.currentPath ?? '';
  // cannot jump to module that has already been visited

  const getRootPath = path => {
    const pathSegments = path.split('.');
    return pathSegments.slice(0, pathSegments.length - 1).join('.');
  };

  const disabled =
    path.includes(modifyPath) || getRootPath(path) === getRootPath(modifyPath);

  return sharedFlow?.state === 'select-module' ? (
    <div className={['absolute -top-3 -left-3'].join(' ')}>
      <div className={`relative`}>
        {sharedFlow.data.currentId === id || disabled ? null : <PingDisplay />}
        <IconButton
          size={'md'}
          disabled={disabled}
          color={sharedFlow.data.currentId === id ? 'positive' : 'accent'}
          onClick={handleSelectModule}
        >
          <Archery fr={undefined} />
        </IconButton>
      </div>
    </div>
  ) : null;
};

const PingDisplay = () => {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => setDisplay(false), 700);
  }, []);

  if (!display) return null;

  return (
    <span className="animate-ping pointer-events-none absolute inline-flex h-full w-full rounded-md bg-accent-60 opacity-75" />
  );
};

export default ModeSelectModuleThis;
