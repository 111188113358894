import { useWebphoneContext } from 'app/components/Webphone';
import * as React from 'react';
import { AntennaOff, Phone, WarningTriangle } from 'iconoir-react';
import { Alert, IconButton } from 'app/design-lib';
import { useDispatch } from 'react-redux';
import { useLocalSlice } from 'app/data/local';
import { LogoIcon } from 'app/design-lib/LogoIcon';

interface LogoAndExpanderProps {}

const LogoAndExpander = (props: LogoAndExpanderProps) => {
  const dispatch = useDispatch();
  const { actions } = useLocalSlice();

  const onClick = () => {
    dispatch(actions.set_webphone_visible(true));
  };

  return (
    <>
      <div
        className={
          'border-b border-gray-20 w-full flex items-center justify-between py-3 px-4'
        }
      >
        <LogoIcon />
        <div className={`relative`}>
          <IconButton
            onClick={onClick}
            variant={'ghost'}
            size={'md'}
            color={'accent'}
          >
            <Phone fr={undefined} />
          </IconButton>
          <WebphoneStatus onClick={onClick} />
        </div>
      </div>
    </>
  );
};

const WebphoneStatus = ({ onClick }) => {
  const [webphoneState] = useWebphoneContext();
  const { isLineConnected, loading } = webphoneState;

  if (!isLineConnected || !isLineConnected()) {
    return (
      <div className={`absolute pointer-events-none top-0 right-0`}>
        {loading ? (
          <AntennaOff
            className={`top-0 mt-0.5 right-0 h-3.5 w-3.5 text-neutral-40`}
            fr={undefined}
          />
        ) : (
          <WarningTriangle
            className={`top-0 mt-0.5 right-0 h-3.5 w-3.5 text-negative-60`}
            fr={undefined}
          />
        )}
      </div>
    );
  }

  return null;
};

export default LogoAndExpander;
