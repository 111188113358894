import {
  ButtonProps,
  default as Button,
} from 'app/design-lib/components/Button/Button';
import { Color } from 'app/design-lib/types';
import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  NavArrowDown as ArrowIcon,
  NavArrowLeft,
  NavArrowRight,
} from 'iconoir-react';
import { IconButton } from 'app/design-lib/components/IconButton';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
export interface IconButtonDropdownProps
  extends Omit<ButtonProps, 'endIcon' | 'type' | 'startIcon' | 'children'> {
  subArrowRight?: boolean;
  children: ReactElement;
  hideArrow?: boolean;
  align?: 'left' | 'right' | 'top' | 'bottom';
  pill?: boolean; // for icon button only
  menuItems: (MenuItem | null)[];
  side?: 'top' | 'bottom' | 'left' | 'right';
}

type MenuItem = {
  onClick: () => void;
  label: ReactNode;
  icon?: ReactElement;
  color?: Color;
  subOptions?: MenuItem[];
  disabled?: boolean;
};

const IconButtonDropdown = ({
  subArrowRight = false,
  align = 'left',
  menuItems,
  hideArrow = false,
  size = 'sm',
  side = 'bottom',
  onClick,
  ...buttonProps
}: IconButtonDropdownProps) => {
  // @ts-ignore
  const menuItemsFiltered: MenuItem[] = menuItems.filter(v => !!v);
  return (
    <DropdownMenu.Root>
      <div className={`flex`}>
        {onClick ? (
          // @ts-ignore
          <IconButton
            size={size}
            {...buttonProps}
            title={'Default template'}
            onClick={onClick}
          />
        ) : null}
        <DropdownMenu.Trigger asChild>
          {hideArrow ? (
            // @ts-ignore
            <IconButton size={size} {...buttonProps} />
          ) : onClick ? (
            // @ts-ignore
            <IconButton {...buttonProps} title={'Additional templates'}>
              <ArrowIcon fr={undefined} />
            </IconButton>
          ) : (
            // @ts-ignore
            <Button
              {...buttonProps}
              size={size}
              children={null}
              startIcon={buttonProps.children}
              endIcon={<ArrowIcon fr={undefined} />}
            />
          )}
        </DropdownMenu.Trigger>
      </div>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={`z-20 rounded-sm bg-white  w-full shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
          sideOffset={8}
          alignOffset={2}
          side={side}
          align={align === 'right' ? 'start' : 'end'}
        >
          {buttonProps.title ? (
            <div
              className={` py-1 px-2 text-sm border-b border-neutral-20 text-neutral-60`}
            >
              {buttonProps.title}
            </div>
          ) : null}
          {menuItemsFiltered.map(
            ({ subOptions, onClick, label, icon, color, disabled }, index) =>
              subOptions?.length ? (
                <SubOptionMenuItem
                  subArrowRight={subArrowRight}
                  option={{ subOptions, onClick, label, icon, color }}
                  index={index}
                  key={index}
                />
              ) : (
                // </DropdownMenu.Item>
                <DropdownMenu.Item key={index}>
                  <div>
                    <button
                      disabled={disabled}
                      className={`${
                        disabled ? 'pointer-events-none opacity-50' : ''
                      } cursor-pointer
                   hover:bg-transparency-10
  active:bg-transparency-20
                   whitespace-nowrap ${
                     color && color !== 'neutral'
                       ? `text-${color}-60`
                       : 'text-neutral-90'
                   }  flex space-x-2 items-center text-right justify-start w-full font-medium text-md font-sans  p-2 m-0`}
                      onClick={onClick}
                    >
                      {icon
                        ? React.cloneElement(icon, {
                            className:
                              color && color !== 'neutral'
                                ? `text-${color}-60`
                                : 'text-neutral-60',
                          })
                        : null}
                      <span>{label}</span>
                    </button>
                  </div>
                </DropdownMenu.Item>
              ),
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );

  // return (
  //   <Menu as="div" className="relative inline-flex item-center">
  //     {hideArrow ? (
  //       // @ts-ignore
  //       <Menu.Button as={IconButton} size={size} {...buttonProps} />
  //     ) : (
  //       // @ts-ignore
  //       <Menu.Button
  //         as={Button}
  //         {...buttonProps}
  //         size={size}
  //         children={null}
  //         startIcon={buttonProps.children}
  //         endIcon={<ArrowIcon fr={undefined} />}
  //       />
  //     )}
  //     <Transition
  //       as={Fragment}
  //       enter="transition ease-out duration-100"
  //       enterFrom="transform opacity-0 scale-95"
  //       enterTo="transform opacity-100 scale-100"
  //       leave="transition ease-in duration-75"
  //       leaveFrom="transform opacity-100 scale-100"
  //       leaveTo="transform opacity-0 scale-95"
  //     >
  //       <Menu.Items
  //         className={`absolute z-10 ${
  //           align === 'left' ? 'left-0' : 'right-0'
  //         } ${
  //           size === 'sm' ? 'top-8' : 'top-10'
  //         } rounded-sm bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
  //       >
  //         {menuItems.map(({ onClick, label, icon, color }, index) => (
  //           <Menu.Item key={index}>
  //             <div>
  //               <button
  //                 className={`cursor-pointer
  //                  hover:bg-transparency-10
  // active:bg-transparency-20
  //                  whitespace-nowrap ${
  //                    color && color !== 'neutral'
  //                      ? `text-${color}-60`
  //                      : 'text-neutral-90'
  //                  }  flex space-x-2 items-center text-right justify-between w-full font-medium text-sm font-sans  p-2 m-0`}
  //                 onClick={onClick}
  //               >
  //                 {icon
  //                   ? React.cloneElement(icon, {
  //                       className:
  //                         color && color !== 'neutral'
  //                           ? `text-${color}-60`
  //                           : 'text-neutral-90',
  //                     })
  //                   : null}
  //                 <span>{label}</span>
  //               </button>
  //             </div>
  //           </Menu.Item>
  //         ))}
  //       </Menu.Items>
  //     </Transition>
  //   </Menu>
  // );
};

const SubOptionMenuItem = ({
  option: { onClick, color, label, icon, subOptions },
  index,
  subArrowRight,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu.Item onClick={onClick} asChild>
      <DropdownMenu.Sub open={open} key={index}>
        <DropdownMenu.SubTrigger asChild>
          {/*<DropdownMenu.Item asChild >*/}
          <div className={'w-full'}>
            <button
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
              onClick={e => {
                // console.log('clicked');
                onClick();
              }}
              className={`h-full w-full cursor-pointer
              ${open ? 'bg-transparency-10' : ''}
                   hover:bg-transparency-10
  active:bg-transparency-20
                   whitespace-nowrap ${
                     color && color !== 'neutral'
                       ? `text-${color}-60`
                       : 'text-neutral-90'
                   }  flex    space-x-0 items-center text-right justify-between w-full font-medium text-sm font-sans  p-2`}
            >
              {icon
                ? React.cloneElement(icon, {
                    className:
                      color && color !== 'neutral'
                        ? `text-${color}-60`
                        : 'text-neutral-90',
                  })
                : !subArrowRight
                ? React.cloneElement(<NavArrowLeft fr={undefined} />, {
                    className: ` ${open ? '' : 'opacity-50'} ${
                      color && color !== 'neutral'
                        ? `text-${color}-60`
                        : 'text-neutral-90'
                    }`,
                  })
                : null}
              <div className={'w-full'}>{label}</div>
              {subArrowRight
                ? React.cloneElement(<NavArrowRight fr={undefined} />, {
                    className: ` ${open ? '' : 'opacity-50'} ${
                      color && color !== 'neutral'
                        ? `text-${color}-60`
                        : 'text-neutral-90'
                    }`,
                  })
                : null}
            </button>
          </div>
        </DropdownMenu.SubTrigger>

        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            // className={'px-8 translate-x-7'}
            className={`z-20`}
            sideOffset={0}
            alignOffset={0}
          >
            <div
              className={` rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
            >
              {/*<DropdownMenu.Arrow className={'-translate-y-6 fill-gray-60'} />*/}
              {subOptions.map((opt, subIndex) => {
                return (
                  <DropdownMenu.Item asChild key={`${index}-${subIndex}`}>
                    <button
                      disabled={opt.disabled}
                      className={`${
                        opt.disabled ? 'pointer-events-none' : ''
                      } cursor-pointer
                   hover:bg-transparency-10
  active:bg-transparency-20
                   whitespace-nowrap ${
                     color && color !== 'neutral'
                       ? `text-${color}-60`
                       : 'text-neutral-90'
                   }  flex space-x-2  items-center text-right justify-between w-full font-medium text-sm font-sans  p-2 m-0`}
                      onClick={opt.onClick}
                    >
                      {opt.icon
                        ? React.cloneElement(opt.icon, {
                            className:
                              color && color !== 'neutral'
                                ? `text-${color}-60`
                                : 'text-neutral-90',
                          })
                        : null}
                      <span>{opt.label}</span>
                    </button>
                  </DropdownMenu.Item>
                );
              })}
            </div>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    </DropdownMenu.Item>
  );
};

export default IconButtonDropdown;
