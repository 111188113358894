import React, { useState, useContext, useEffect, useRef, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import useDevMode from 'app/utilities/useDevMode';
import { Button, IconButton, Tooltip } from 'app/design-lib';
import {
  Phone as PhoneIcon,
  NoteAdd as NoteAddIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities';
import { useHoverDirty } from 'react-use';
import { useBuilderContext } from '../../../IvrBuilder';

const NoteNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
  } = data;

  const { devMode } = useDevMode();

  const { miniMap } = useBuilderContext();

  const boxRef = useRef(null);
  const isHovering1 = useHoverDirty(boxRef);

  const hoverRef = useRef(null);
  const isHovering2 = useHoverDirty(hoverRef);
  const isHoveringEither = isHovering1 || isHovering2 ? true : false;

  const handleAddNote = () => {
    // use same as InsertAbove (ie, push stuff down, insert a NoteNode here)
    const noteText = prompt('Note:', '');
    if (noteText === null || !noteText?.length) {
      // cancel
      return false;
    }
    const { callflow, setCallflow, modifyPath } = insertAfterData;
    // console.log('cc:', modifyPath, callflow);
    const data = {
      type: 'Note',
      data: {
        text: noteText.toString(),
        options: {},
      },
    };
    const index = insertAfterData.index - 1;
    const stratModules = getAtPath(
      callflow,
      `${modifyPath}.strategy.data.modules`,
    );
    stratModules.splice(index, 0, data);
    setAtPath(callflow, `${modifyPath}.strategy.data.modules`, stratModules);
    setCallflow({ ...callflow });
    // // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('node-created', {
    //     key: `${modifyPath}.strategy.config.components[${index}]`,
    //     optsPath: `${modifyPath}.strategy.data.opts[${index}]`,
    //   });
    // }, 100);
  };

  const handleEditNote = () => {
    const noteText = prompt('Note:', moduleItem?.data?.text);
    if (noteText === null) {
      // cancel
      return false;
    }
    // empty = delete note!
    if (!noteText?.length) {
      // delete
      const { index, callflow, setCallflow, modifyPath } = insertAfterData;
      const stratDataModules = getAtPath(
        callflow,
        `${modifyPath}.strategy.data.modules`,
      );
      // index is from the insertAfterData!! (ie, we need to -1 to remove from the correct place)
      stratDataModules.splice(index - 1, 1);
      setAtPath(
        callflow,
        `${modifyPath}.strategy.data.modules`,
        stratDataModules,
      );
      setCallflow({ ...callflow });
    } else {
      // modify
      moduleItem.data.text = noteText;
      setAtPath(callflow, modifyPath, moduleItem);
      setCallflow({ ...callflow });
    }
  };

  if (!devMode || miniMap || (skipEditing && !moduleItem?.data?.text?.length)) {
    return (
      <div ref={boxRef} className="h-0 inline-block text-center relative">
        <div className={`h-[0px] w-[1px]`}></div>
        <Handle
          type="source"
          position="bottom"
          style={{
            height: '2px',
            top: 'auto',
            bottom: 1,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    );
  }

  return (
    <>
      {!moduleItem?.data ? (
        // <Tooltip title={<>Add a Note</>} placement="bottom" type="light" arrow>
        <div ref={boxRef} className={'inline-block text-center relative'}>
          {/* <div
            ref={hoverRef}
            className={'absolute'}
            style={{
              // background: isHoveringEither ? 'red' : 'none',
              position: 'absolute',
              // top: -18,
              left: -12,
              width: 'calc(100% + 24px)',
              // height: 'calc(100% + 36px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: isHoveringEither ? 1 : 0,
            }}
          >
            <IconButton
              size={'md'}
              variant="outline"
              // style={{
              //   opacity: 0.5,
              //   marginLeft2: isHoveringEither ? -16 : 0,
              // }}
              onClick={handleAddNote}
            >
              <NoteAddIcon />
            </IconButton>
          </div> */}
          {/* Put the Handle below, so it kinda goes "behind" the note (so the note takes up less space between modules) */}
          <IconButton
            // position={data?.position}
            size={isHoveringEither ? 'sm' : 'sm'}
            clear
            // variant="outline"
            variant="ghost"
            style={{
              opacity: isHoveringEither ? 1 : 0.2,
              marginLeft2: isHoveringEither ? -16 : 0,
            }}
            onClick={handleAddNote}
          >
            <>
              <Handle
                type="target"
                position="top"
                style={{ background: '#555', visibility: 'hidden' }}
              />
              {/* DO NOT UNCOMMENT NEXT LINE */}
              <NoteAddIcon sx={{ height: 16, width: 16 }} />
              <Handle
                type="source"
                position="bottom"
                style={{
                  // top: 'auto',
                  // bottom: 1,
                  background: '#555',
                  visibility: 'hidden',
                }}
              />
            </>
          </IconButton>
        </div>
      ) : (
        // </Tooltip>
        <div className="relative">
          <Handle
            type="target"
            position="top"
            style={{ background: '#555', visibility: 'hidden' }}
          />
          <div
            onClick={handleEditNote}
            className="cursor-pointer bg-[#FFEEB0] p-2 px-3 rounded-md border border-neutral-20 shadow-neutral-40"
            // sx={{
            //   cursor: 'pointer',
            //   background: '#FFEEB0',
            //   padding: 1,
            //   borderRadius: 1,
            //   border: '1px solid #eaeaea',
            //   boxShadow: 'rgb(90 71 2 / 20%) 0px 2px 10px 0px',
            // }}
          >
            <Tooltip
              title={<>Click to Modify Note</>}
              placement="bottom"
              type="light"
              arrow
            >
              <div style={{ maxWidth: 180 }}>{moduleItem?.data?.text}</div>
            </Tooltip>
          </div>
          <Handle
            type="source"
            position="bottom"
            style={{
              top: 'auto',
              bottom: 1,
              background: '#555',
              visibility: 'hidden',
            }}
          />
        </div>
      )}
    </>
  );
});

export default NoteNode;
