import { Color, Size, Variant } from 'app/design-lib/types';
import React, { ReactElement, ReactNode } from 'react';
import { Cancel as CloseIcon } from 'iconoir-react';
import CircleIcon from '@mui/icons-material/Circle';

export type BadgeVariant = 'fill' | 'outline';
export interface BadgeProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'size'
  > {
  color: Color;
  size?: Size;
  variant: BadgeVariant;
  children: ReactNode;
  startIcon?: ReactElement;
  onClose?: () => void;
  reverse?: boolean;
  isActive?: boolean;
}

const Badge = ({
  variant = 'fill',
  size = 'sm',
  color = 'neutral',
  children,
  startIcon,
  onClose,
  reverse = false,
  isActive = false,
  ...props
}: BadgeProps) => {
  let className = `whitespace-nowrap badge ${getSize(size)} ${getVariantStyling(
    variant,
    color,
    reverse,
    isActive,
  )} ${props.className} rounded-full`;

  return (
    <button
      {...props}
      className={`${className} ${
        props.onClick || onClose ? '' : 'cursor-default'
      }`}
    >
      {startIcon ? (
        React.cloneElement(startIcon, { className: getIconSize(size) })
      ) : (
        <div></div>
      )}
      <span className={''}>{children}</span>
      {onClose ? (
        <button onClick={onClose}>
          <CloseIcon fr={undefined} className={getIconSize(size)} />
        </button>
      ) : (
        <div></div>
      )}
    </button>
  );
};

const getIconSize = (size: Size) => {
  switch (size) {
    case 'sm':
      return `h-3.5 w-3.5`;
    case 'md':
      return `h-4.5 w-4.5`;
    case 'lg':
      return `h-5 w-5`;
  }
};

const OverlapStyles = (color: Color) => `
  bg-white 
  hover:bg-transparency-10 
  active:bg-transparency-20
  ${color === 'neutral' ? `active:text-${color}-90` : `active:text-${color}-80`}
  text-${color}-60 
  hover:text-${color}-70 
  
  disabled:text-neutral-20
`;

const ReverseOverlapStyles = (color: Color) => `
  bg-neutral-90 
  hover:bg-transparency-30
  active:bg-transparency-60
  text-${color}-30 
  hover:text-${color}-40 
  active:text-${color}-50 
  disabled:text-neutral-60
`;

const getVariantStyling = (
  variant: BadgeVariant,
  color: Color,
  reverse: boolean,
  isActive: boolean,
) => {
  if (reverse) {
    switch (variant) {
      case 'fill':
        return `
      border
  
      hover:border-${color}-30 
      active:border-${color}-40
      ${
        isActive
          ? `
      bg-${color}-40
      border-${color}-40
      text-${color}-90
      `
          : `
       bg-${color}-10
         border-${color}-10
         text-${color}-60 
        `
      }
      hover:bg-${color}-30 
      active:bg-${color}-40
      disabled:bg-neutral-60
      hover:text-${color}-80
      active:text-${color}-90
      disabled:text-neutral-30
      `;
      case 'outline':
        return `
      border
      border-${color}-60 
      hover:border-${color}-70 
      active:border-${color}-80 
      disabled:border-neutral-60 
      ${ReverseOverlapStyles(color)}    
      `;
    }
  }

  switch (variant) {
    case 'fill':
      return `
      border
      hover:border-${color}-70 
      active:border-${color}-80
      ${
        isActive
          ? `
      bg-${color}-80
      border-${color}-80
      `
          : `
        bg-${color}-60
         border-${color}-60 
        `
      }
      hover:bg-${color}-70 
      active:bg-${color}-80
      text-white disabled:bg-neutral-20`;
    case 'outline':
      return `
      border
      border-${color}-30 
      hover:border-${color}-40 
      active:border-${color}-50
      disabled:border-neutral-20 
      ${OverlapStyles(color)}    
      `;
  }
};

const getSize = (size: Size) => {
  switch (size) {
    case 'sm':
      return `py-0.5 px-1 text-sm space-x-1 font-medium`;
    case 'md':
      return `py-1 px-2 text-sm space-x-1 font-medium`;
    case 'lg':
      return `py-1.5 px-2 text-md space-x-1.5 font-medium`;
  }
};
export default Badge;
