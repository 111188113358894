import {
  Archery,
  Calendar,
  Cancel,
  Clock,
  Community,
  DeleteCircle,
  Dialpad,
  Group,
  Message,
  MusicNote,
  Phone,
  PhoneDisabled,
  PhoneIncome,
  Plus,
  QuestionMark,
  SkipNext,
  Strategy,
  TimerOff,
  User,
  Voice,
} from 'iconoir-react';
import React from 'react';
import { MenuKeyMap } from '../CallflowSidebar';

export const getElementProps = element => {
  if (!element)
    return {
      icon: <QuestionMark fr={undefined} />,
      name: 'Module Missing',
      type: 'Module Missing',
    };

  let icon;
  let name;
  let type;

  let nodeType;
  let color = 'accent';

  switch (element.type) {
    case 'InsertMenuOption':
    case 'InsertScheduleOption':
      icon = <Plus fr={undefined} />;
      type = 'Add Option';
      name = 'Add Option';
      break;

    case 'OptionNode':
      switch (element.data.targetKey) {
        case '_':
          icon = <Cancel fr={undefined} />;
          type = 'Invalid Entry';
          name = element.data.name ?? type;
          break;
        case 'timeout':
          icon = <TimerOff fr={undefined} />;
          type = 'Menu Timeout';
          name = element.data.name ?? type;
          break;
        default:
          icon = MenuKeyMap.get(element.data.targetKey);
          type = `Dial ${element.data.targetKey}`;
          name = element.data.name ?? type;
          break;
      }
      break;
    case 'OptionScheduleNode':
      switch (element.data.category.id) {
        case 'fallback':
          icon = <DeleteCircle fr={undefined} />;
          type = 'Schedule Fallback';
          name = element.data.category.name ?? 'Fallback';
          break;
        default:
          icon = <Calendar fr={undefined} />;
          type = 'Schedule Route';
          name = element.data.category.name ?? `Route`;
          break;
      }
      break;
    default:
      const module = element.data?.moduleItem;

      if (!module)
        return {
          icon: <QuestionMark fr={undefined} />,
          name: 'Module Missing',
          type: 'Module Missing',
        };

      switch (module.type) {
        case 'Root':
          nodeType = 'root';
          color = 'positive';
          name = 'Incoming Call';
          type = 'Incoming Call';
          icon = <PhoneIncome fr={undefined} />;
          break;
        // RING MODULE
        case 'Ring':
          nodeType = 'passthrough';
          switch (module.data.ring_type) {
            case 'line':
              icon = <Community fr={undefined} />;
              type = `Ring Line's Users`;
              name = module.name ?? type;
              break;
            case 'group':
              icon = <Group fr={undefined} />;
              type = `Ring Group`;
              name = module.name ?? type;

              break;
            case 'user':
              icon = <User fr={undefined} />;
              type = `Ring User`;
              name = module.name ?? type;
              break;
            case 'device':
              icon = <Phone fr={undefined} />;
              type = `Ring Device`;
              name = module.name ?? type;
              break;
          }
          break;
        // SEND MESSAGE
        case 'Message':
          icon = <Message fr={undefined} />;
          type = `Send Message`;
          name = module.name ?? type;
          nodeType = 'passthrough';
          break;
        // VOICEMAIL
        case 'PlayAudio':
          icon = <MusicNote fr={undefined} />;
          type = `Play Audio`;
          name = module.name ?? type;
          nodeType = 'passthrough';
          break;
        // VOICEMAIL
        case 'VoicemailBox':
          icon = <Voice fr={undefined} />;
          type = `Voicemail`;
          name = module.name ?? type;
          nodeType = 'terminal';
          break;
        // TRANSFER
        case 'Transfer':
          icon = <Strategy fr={undefined} />;
          type = `Transfer to Line`;
          name = module.name ?? type;
          nodeType = 'terminal';
          break;
        // HANGUP
        case 'Hangup':
          icon = <PhoneDisabled fr={undefined} />;
          type = `Hangup`;
          name = module.name ?? type;
          nodeType = 'terminal';
          break;
        // HANGUP
        case 'GoToInFlow':
          icon = <SkipNext fr={undefined} />;
          type = `Jump to Module`;
          name = module.name ?? type;
          nodeType = 'terminal';
          break;
        // MENU
        case 'Menu':
          icon = <Dialpad fr={undefined} />;
          type = `Menu`;
          name = module.name ?? type;
          nodeType = 'branching';
          break;
        // Schedule
        case 'Schedule':
          icon = <Clock fr={undefined} />;
          type = `Schedule`;
          name = module.name ?? type;
          nodeType = 'branching';
          break;
        default:
          name = 'Unknown';
          type = `Unknown`;
          icon = <QuestionMark fr={undefined} />;
          console.log('unknown data', element);
          break;
      }
  }

  return { icon, name, type, nodeType, color };
};
