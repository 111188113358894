import TextToSpeechView from 'app/components/AudioEditor/components/TextToSpeechView/TextToSpeechView';
import { AudioPlayer } from 'app/components/AudioPlayer';
import {
  Alert,
  Button,
  IconButton,
  Listbox,
  SquareProgress,
} from 'app/design-lib';
import { useListMediasQuery } from 'app/hooks/queries/media';
import {
  Cancel,
  Mic as MicIcon,
  MusicNoteAdd,
  Playlist as PlaylistIcon,
  Upload as UploadIcon,
  Text as TextIcon,
} from 'iconoir-react';
import { useState } from 'react';
import { MediaType } from 'types/media';
import LibraryView from 'app/components/AudioEditor/components/LibraryView/LibraryView';
import UploadView from 'app/components/AudioEditor/components/UploadView/UploadView';
import RecordView from './components/RecordView/RecordView';
import { startCase } from 'lodash';

interface AudioEditorProps {
  mediaId?: string;
  onClose: () => void;
  isLoading?: boolean;
  loadingLabel?: string;
  onComplete: (mediaId: string | null) => Promise<void>;
  subType: MediaSubType;
  mediaName?: string;
  hideCancel?: boolean;
  hasPlatformDefault?: boolean;
}

export type MediaSubType =
  | 'hold-music'
  | 'play-audio'
  | 'vmbox-greeting'
  | 'menu-greeting';

const AudioEditor = ({
  mediaId,
  onClose,
  onComplete,
  isLoading,
  loadingLabel,
  hideCancel,
  subType,
  mediaName = '',
  hasPlatformDefault = true,
}: AudioEditorProps) => {
  const [mediaType, setMediaType] = useState<
    'library' | 'upload' | 'record' | 'tts'
  >();
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const mediaListQuery = useListMediasQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    orderBy: [['name', 'asc']],
    filters: {
      raw: [{ variables: { docFilters: [['sub_type', '=', subType]] } }],
    },
  });

  const media = [
    ...(subType === 'hold-music'
      ? [
          {
            name: 'Digital Sym-Phoney',
            id: 'https://ucarecdn.com/baf86f0c-ead3-426d-a0f0-cdad7d683f0b/',
            type: 'built-in',
          },
          {
            name: 'Hold the Xylo-Phone',
            id: 'https://ucarecdn.com/19a3becb-cd77-45e0-bac3-982f974f8a27/',
            type: 'built-in',
          },
          {
            name: 'Easy Morning',
            id: 'https://objective-liskov-9272da.netlify.app/morning-with-coffee-loop.mp3',
            type: 'built-in',
          },
          {
            name: 'Exciting Mystery',
            id: 'https://objective-liskov-9272da.netlify.app/the-power-inside.mp3',
            type: 'built-in',
          },
        ]
      : []),
    ...(mediaListQuery.data?.medias.map(media => ({
      name: media.doc.name,
      id: media.id,
      type:
        media.doc.media_source === 'tts' ||
        (media.doc.media_source === 'upload' &&
          media.doc.upload_subtype === 'tts')
          ? 'TTS'
          : media.doc.media_source === 'upload'
          ? 'Uploaded'
          : startCase(media.doc.media_source),
      data:
        media.doc.upload_subtype === 'tts' ? media.doc.tts?.text : undefined,
    })) ?? []),
  ];

  const clearMedia = async () => {
    await onComplete(null);
  };

  const isGreeting =
    subType === 'vmbox-greeting' || subType === 'menu-greeting';

  return (
    <div
      className={`border overflow-visible border-neutral-20 flex w-full flex-col space-y-2 rounded-lg py-2.5 px-3`}
    >
      {isLoading && !isMutating ? (
        <div className={`w-full h-full grid place-items-center`}>
          <div className={`flex flex-col items-center justify-center`}>
            <SquareProgress />
            <span className={`font-medium text-md`}>
              {loadingLabel ?? 'Updating media...'}
            </span>
          </div>
        </div>
      ) : (
        <>
          {isMutating ? null : (
            <>
              <div className={`flex space-x-2 w-full justify-end`}>
                {/*<div className={`text-md font-medium`}>Audio</div>*/}
                {mediaId ? (
                  <Button
                    startIcon={<Cancel fr={undefined} />}
                    color={'neutral'}
                    variant={'ghost'}
                    size={'sm'}
                    onClick={clearMedia}
                  >
                    {subType === 'hold-music'
                      ? 'Clear hold music'
                      : subType === 'play-audio'
                      ? 'Clear audio'
                      : 'Clear greeting'}
                  </Button>
                ) : hideCancel ? null : (
                  <Button
                    startIcon={<Cancel fr={undefined} />}
                    color={'neutral'}
                    variant={'ghost'}
                    size={'sm'}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                )}
              </div>
              {
                mediaId ? (
                  <AudioPlayer mediaId={mediaId} />
                ) : hasPlatformDefault ? (
                  <Alert
                    reverse
                    icon={<MusicNoteAdd fr={undefined} />}
                    label={'Using platform default'}
                  />
                ) : null
                //       (
                // <Alert
                //   reverse
                //   color="attention"
                //   icon={<MusicNoteAdd fr={undefined} />}
                //   label={'No audio selected yet'}
                // />
                //     )
              }
              <div className={`w-full sm:w-1/2`}>
                <Listbox
                  // label={'Type'}
                  value={mediaType}
                  options={
                    subType === 'hold-music'
                      ? [
                          {
                            value: 'library',
                            label: 'Hold-Music Library',
                            icon: <PlaylistIcon fr={undefined} />,
                          },
                          {
                            value: 'upload',
                            label: 'Upload',
                            icon: <UploadIcon fr={undefined} />,
                          },
                        ]
                      : subType === 'play-audio'
                      ? [
                          {
                            value: 'library',
                            label: 'Audio Library',
                            icon: <PlaylistIcon fr={undefined} />,
                          },
                          {
                            value: 'record',
                            label: 'Record',
                            icon: <MicIcon fr={undefined} />,
                          },
                          {
                            value: 'upload',
                            label: 'Upload',
                            icon: <UploadIcon fr={undefined} />,
                          },
                          {
                            value: 'tts',
                            label: 'Text-to-speech',
                            icon: <TextIcon fr={undefined} />,
                          },
                          // ...(media.length
                          //   ? [
                          //       {
                          //         value: 'library',
                          //         label: 'Library',
                          //         icon: <PlaylistIcon fr={undefined} />,
                          //       },
                          //     ]
                          //   : []),
                        ]
                      : [
                          {
                            value: 'library',
                            label: `${subType
                              .split('-')
                              .map(p => startCase(p))
                              .join(' ')
                              .replace('Vmbox', 'Voicemail')} Library`,
                            icon: <PlaylistIcon fr={undefined} />,
                          },
                          {
                            value: 'record',
                            label: 'Record',
                            icon: <MicIcon fr={undefined} />,
                          },
                          {
                            value: 'upload',
                            label: 'Upload',
                            icon: <UploadIcon fr={undefined} />,
                          },
                          {
                            value: 'tts',
                            label: 'Text-to-speech',
                            icon: <TextIcon fr={undefined} />,
                          },
                          // ...(media.length
                          //   ? [
                          //       {
                          //         value: 'library',
                          //         label: 'Library',
                          //         icon: <PlaylistIcon fr={undefined} />,
                          //       },
                          //     ]
                          //   : []),
                        ]
                  }
                  onChange={setMediaType}
                  className={`w-full`}
                  placeholder={`Choose type of new audio`}
                />
              </div>
            </>
          )}
        </>
      )}
      <div className={isMutating ? '' : `py-1`}>
        {mediaType === 'library' ? (
          <LibraryView
            setIsMutating={setIsMutating}
            refetch={mediaListQuery.refetch}
            onCancel={onClose}
            onComplete={onComplete}
            media={media}
            loadingLabel={loadingLabel}
          />
        ) : null}
        {mediaType === 'upload' ? (
          <UploadView
            subType={subType}
            onCancel={onClose}
            onComplete={onComplete}
            mediaId={subType === 'vmbox-greeting' ? mediaId : undefined}
            isGreeting={isGreeting}
            mediaName={mediaName}
            setIsMutating={setIsMutating}
            loadingLabel={loadingLabel}
          />
        ) : null}
        {mediaType === 'record' ? (
          <RecordView
            isGreeting={isGreeting}
            subType={subType}
            onCancel={onClose}
            onComplete={onComplete}
            mediaName={mediaName}
            mediaId={subType === 'vmbox-greeting' ? mediaId : undefined}
            setIsMutating={setIsMutating}
            loadingLabel={loadingLabel}
          />
        ) : null}
        {mediaType === 'tts' ? (
          <TextToSpeechView
            subType={subType}
            isGreeting={isGreeting}
            onCancel={onClose}
            onComplete={onComplete}
            mediaName={mediaName}
            mediaId={subType === 'vmbox-greeting' ? mediaId : undefined}
            setIsMutating={setIsMutating}
            loadingLabel={loadingLabel}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AudioEditor;
